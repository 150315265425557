<template>
  <ReporteLayout
    titulo="Reportes - Ventas por área"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
    tipoTransaccion="venta"
  />
</template>

<script>
import ReporteLayout from "../../components/ReporteLayout.vue";
import ReporteVentasService from "../services";

export default {
  name: "ReporteVentasDetalladasLayout",
  components: {
    ReporteLayout,
  },
  data() {
    return {
      componenteListado: "ListadoTransaccionesPorArea",
    };
  },
  methods: {
    servicioGetReporte: ReporteVentasService.getReporteVentasPorArea,
    servicioGetReportePdf: ReporteVentasService.getReporteVentasPorAreaPdf,
  },
};
</script>